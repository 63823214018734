import React from 'react'
import {
  FaSquarePhone,
  FaSquareEnvelope,
  FaSquareFacebook,
  FaSquareInstagram,
} from 'react-icons/fa6'

const Footer = () => {
  const currentDate = new Date().getFullYear()
  return (
    <>
      <footer className='footer'>
        <div className='footer__socials'>
          <a href='https://www.facebook.com/LDTHlavas/' target='blank'>
            <FaSquareFacebook className='footer__socials__icon' />
          </a>
          <a
            href='https://instagram.com/ldthlavas?igshid=YmMyMTA2M2Y='
            target='blank'
          >
            <FaSquareInstagram className='footer__socials__icon' />
          </a>
          <a href='mailto:hlavas@hotmail.com'>
            <FaSquareEnvelope className='footer__socials__icon' />
          </a>
          <a href='tel:420739001411'>
            <FaSquarePhone className='footer__socials__icon' />
          </a>
        </div>
        <div className='footer__copyright'>
          Copyright &copy; {currentDate} Hlavas, z.s.
        </div>
      </footer>
    </>
  )
}

export default Footer
