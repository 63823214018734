import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import YearLogo from '../resources/imgs/card.png'
import {
  FaSquarePhone,
  FaSquareEnvelope,
  FaSquareFacebook,
  FaSquareInstagram,
  FaAnglesDown,
} from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

const MainPage = () => {
  // HEADER ARROW SCROLL
  const handleScrollDown = () => {
    scroll.scrollTo(window.innerHeight, {
      duration: 800,
      smooth: 'easeInOutSine',
    })
  }

  return (
    <>
      <Navbar />
      <header className='header'>
        <div className='header__socials'>
          <a href='https://www.facebook.com/LDTHlavas/' target='blank'>
            <FaSquareFacebook className='header__socials__icon' />
          </a>
          <a
            href='https://instagram.com/ldthlavas?igshid=YmMyMTA2M2Y='
            target='blank'
          >
            <FaSquareInstagram className='header__socials__icon' />
          </a>
          <a href='mailto:hlavas@hotmail.com'>
            <FaSquareEnvelope className='header__socials__icon' />
          </a>
          <a href='tel:420739001411'>
            <FaSquarePhone className='header__socials__icon' />
          </a>
        </div>
        <div className='header__title'>Tomášov 2024</div>
        <div className='header__subtitle'>10.8. - 24.8.</div>
        <div className='header__arrow' onClick={handleScrollDown}>
          <FaAnglesDown className='header__arrow__icon' />
        </div>
        <Link to='/aktuality'>
          <div className='news-box'>
            <div className='news-box__date'>
              19.9.<span>2024</span>
            </div>
            <div className='news-box__message'>Ohlédnutí za Tomášovem</div>
          </div>
        </Link>
      </header>
      <section className='about'>
        <div className='about__left'>
          <div className='about__left__title'>Ročník 2024</div>
          <div className='about__left__info'>
            Vážení přátelé a táboroví příznivci, rádi bychom Vás pozvali na
            další ročník našeho tábora, který se bude konat ve dnech{' '}
            <b>10.8. - 24.8.2024.</b> I v letošním roce jsme se rozhodli pro
            táborovou základnu <b>Tomášov</b> poblíž obce Mikulášovice ve{' '}
            <b>Šluknovském výběžku.</b>
          </div>
          <div className='about__left__info'>
            <b>Tábor je určen pro děti ve věku 6 – 15 let,</b> o které se
            starají zkušení vedoucí, kteří na minulých táborech strávili řadu
            letních prázdnin. Ubytování je pro menší děti zajištěno ve{' '}
            <b>zděných budovách</b>, pro starší v <b>pětilůžkových chatkách.</b>{' '}
            Stravování probíhá 5x denně, pitný režim je zajištěn po celý den.
          </div>
          <div className='about__left__info'>
            Cena pobytu je stanovena na <b>6 000 Kč.</b> Autobusová doprava pro
            odvoz dětí na tábor i zpět je <b>zajištěna</b> pro všechny děti,
            které se tábora zúčastní. Zálohu ve výši 1 000 Kč prosíme uhradit
            nejpozději do <b>30.4.2024</b>, zbytek částky pak nejpozději do{' '}
            <b>30.6.2024</b> na číslo účtu <b>123-3451350257/0100,</b> do zprávy
            pro příjemce uveďte jméno a příjmení dítěte.
          </div>
          <Link to='/rocnik' className='about__left__btn'>
            Více informací
          </Link>
        </div>
        <div className='about__right'>
          <div className='about__left__title'>Celotáborová hra</div>
          <img src={YearLogo} alt='' className='about__right__img' />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default MainPage
