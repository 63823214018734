import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import PageHeader from '../layout/PageHeader'

const History = () => {
  return (
    <>
      <Navbar />
      <PageHeader />
      <section className='news'>
        <div className='news__title'>Historie</div>

        <div className='timeline'>
          <div className='container left'>
            <div className='date'>2024</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Tajemství Ravencroftu</h2>
              <p>
                Druhý ročník v Tomášově nám představil magickou akademii
                Ravencroft.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2023</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Děti rodu Bertinelli</h2>
              <p>
                Při prvním ročníku v Tomášově jsmě byli svědky války mafiánských
                gangů.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2022</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Na skok do budoucnosti</h2>
              <p>
                První a zároveň poslední ročník ve Stvolínkách nás zavedl do
                daleké budoucnosti.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2021</div>
            <i className='icon fa fa-gift'></i>
            <div className='content'>
              <h2>Trosečník</h2>
              <p>Poslední ročnník v Božanově byl ve znamení útěku z ostrova.</p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2020</div>
            <i className='icon fa fa-user'></i>
            <div className='content'>
              <h2>Western</h2>
              <p>Tentokrát jsme v Božanově zavítali na divoký západ.</p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2019</div>
            <i className='icon fa fa-running'></i>
            <div className='content'>
              <h2>Alenka v říši divů</h2>
              <p>Společně s Alenkou jsme z Božanova pronikli do říše divů.</p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2018</div>
            <i className='icon fa fa-cog'></i>
            <div className='content'>
              <h2>Mauglí</h2>
              <p>Božanov tentokrát posloužil jako Kiplingova džungle.</p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2017</div>
            <i className='icon fa fa-certificate'></i>
            <div className='content'>
              <h2>Ve jménu růže, a přesto prokletá</h2>
              <p>
                V novém tábořišti ve východočeském Božanově jsme společně s paní
                Perchtou nahlédli do pozdního středověku.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2016</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Detektivní záhady se Čmelákem</h2>
              <p>
                Jediná zastávka na Čmeláku poblíž řeky Sázavy se nesla v
                detektivním duchu.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2015</div>
            <i className='icon fa fa-gift'></i>
            <div className='content'>
              <h2>Putování za černými faraony</h2>
              <p>Poslední ročník na Klepákově Mlýně.</p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2014</div>
            <i className='icon fa fa-user'></i>
            <div className='content'>
              <h2>Boj o Narnii</h2>
              <p>
                Společně se sourozenci Pevensieovými jsme na Klepákově Mlýně
                zavítali do Narnie.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2013</div>
            <i className='icon fa fa-running'></i>
            <div className='content'>
              <h2>Po stopách podivuhodné cesty Julese Verna</h2>
              <p>
                Druhý Klepákův Mlýn nás nechal detailně probádat příběhy
                slavného spisovatele.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2012</div>
            <i className='icon fa fa-cog'></i>
            <div className='content'>
              <h2>Klepákův Mlýn a Sluneční zátoka</h2>
              <p>
                Při naší premiéře na Klepákově Mlýně v Jihočeském kraji jsme se
                vydali za tajemstvím Sluneční zátoky.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2011</div>
            <i className='icon fa fa-certificate'></i>
            <div className='content'>
              <h2>Krok za krokem českými dějinami</h2>
              <p>
                Poslední ročník na Kamenci nás úspěšně provedl českými dějinami.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2010</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Pojďte s námi do pohádky</h2>
              <p>Tentokrát jsme na Kamenci prošli světem pohádek.</p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2009</div>
            <i className='icon fa fa-gift'></i>
            <div className='content'>
              <h2>Fernao de Magalhaes</h2>
              <p>
                Čtvrtý ročník na Kamenci jsme se nalodili na Fernaovu výpravu.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2008</div>
            <i className='icon fa fa-user'></i>
            <div className='content'>
              <h2>Simpsonovi</h2>
              <p>
                Kamenec v Orlických horách se nám proměnil ve fiktivní americký
                Springfield.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2007</div>
            <i className='icon fa fa-running'></i>
            <div className='content'>
              <h2>Záhada Zlaté štoly</h2>
              <p>Druhý ročník na Kamenci jsme odhalili Záhadu Zlaté štoly.</p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2006</div>
            <i className='icon fa fa-cog'></i>
            <div className='content'>
              <h2>Jumanji</h2>
              <p>
                Nové tábořiště v Kamenci v Orlických horách bylo ve znamení boje
                na život a na smrt.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2005</div>
            <i className='icon fa fa-certificate'></i>
            <div className='content'>
              <h2>České zvyky a obyčeje</h2>
              <p>
                Při poslední návštěvě Hněvětic jsme se ponořili do české
                tradice.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2004</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Harry Potter</h2>
              <p>
                Hněvětice se po páté proměnily ve Školu čar a kouzel v
                Bradavicích.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2003</div>
            <i className='icon fa fa-gift'></i>
            <div className='content'>
              <h2>Stroj času</h2>
              <p>Program čtvrtého ročníku v Hněvěticích ovládalo kolo času.</p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2002</div>
            <i className='icon fa fa-user'></i>
            <div className='content'>
              <h2>Putování po Evropské unii</h2>
              <p>
                Společně jsme v Hněvěticích procestovali všechny státy Evropské
                unie.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>2001</div>
            <i className='icon fa fa-running'></i>
            <div className='content'>
              <h2>Mimozemšťané</h2>
              <p>
                Druhé Hněvětice se nesly v duchu návštěvy z jiného konce
                galaxie.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>2000</div>
            <i className='icon fa fa-cog'></i>
            <div className='content'>
              <h2>Rychmburská strašidla</h2>
              <p>
                Na prvním ročníku v nové táborové základně Hněvětice jsme se
                zapletli s místními strašidly.
              </p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>1999</div>
            <i className='icon fa fa-certificate'></i>
            <div className='content'>
              <h2>Pevnost Boyard</h2>
              <p>
                Jediný rok, ve kterém jsme byli na táborové základně Roseč, nás
                přivedl do pevnosti Boyard.
              </p>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>1998</div>
            <i className='icon fa fa-home'></i>
            <div className='content'>
              <h2>Cesta kolem světa</h2>
              <p>Poslední ročník v Chrašťovicích v doprovodu Willyho Foga.</p>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>1997</div>
            <i className='icon fa fa-gift'></i>
            <div className='content'>
              <h2>Chrašťovice 1997</h2>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>1996</div>
            <i className='icon fa fa-user'></i>
            <div className='content'>
              <h2>Chrašťovice 1996</h2>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>1995</div>
            <i className='icon fa fa-running'></i>
            <div className='content'>
              <h2>Chrašťovice 1995</h2>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>1994</div>
            <i className='icon fa fa-cog'></i>
            <div className='content'>
              <h2>Přemilov 1994</h2>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>1993</div>
            <i className='icon fa fa-certificate'></i>
            <div className='content'>
              <h2>Přemilov 1993</h2>
            </div>
          </div>
          <div className='container left'>
            <div className='date'>1992</div>
            <i className='icon fa fa-cog'></i>
            <div className='content'>
              <h2>Přemilov 1992</h2>
            </div>
          </div>
          <div className='container right'>
            <div className='date'>1991</div>
            <i className='icon fa fa-certificate'></i>
            <div className='content'>
              <h2>Přemilov 1991</h2>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default History
