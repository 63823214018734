import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import PageHeader from '../layout/PageHeader'
// import NewsBox from '../components/NewsBox'
// import news from '../resources/news.json'

// DOCS IMPORTS
import LostFounds from '../resources/docs/zn.pdf'
import Praha from '../resources/docs/Praha2024.pdf'
import Brandys from '../resources/docs/Brandys2024.pdf'

const News = () => {
  return (
    <>
      <Navbar />
      <PageHeader />
      <section className='news'>
        <div className='news__title'>Aktuality</div>
        <div className='news__box'>
          <div className='news__item'>
            <div className='news__item__date'>
              19.9.<span>2024</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Ohlédnutí za Tomášovem</div>
              <div className='news__item__message'>
                Milí táborníci, ještě pořád se nám nechce věřit, že je po
                táboře. Rádi bychom vám z celého srdce poděkovali, že jste s
                námi strávili 14 dní v Tomášově a společně zažili
                nezapomenutelný konec léta! Doufáme, že se vám letošní ročník
                líbil, my jsme si ho náramně užili, a věříme, že vy ještě víc.
                Děkujeme vám za krásné chvíle a těšíme se, že se za rok zase
                společně sejdeme! Navíc jsme na web přidali fotogalerii plnou
                krásných vzpomínek a během podzimu se můžete těšit na
                aftermovie, které vám táborové zážitky znovu připomene.
              </div>
            </div>
          </div>
          <div className='news__item'>
            <div className='news__item__date'>
              19.8.<span>2024</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Rozpis odjezdů z tábora</div>
              <div className='news__item__message'>
                Dovolujeme si vás informovat, že v sobotu 24.8. proběhne odjezd
                z Tomášova ve 12:00. Příjezd autobusu do Brandýsa nad Labem je
                plánovaný na 14:30, do Prahy pak na 15:00. Případné drobné
                odchylky od plánu budeme komunikovat na našich sociálních
                sítích.
              </div>
            </div>
          </div>
          <div className='news__item'>
            <div className='news__item__date'>
              3.8.<span>2024</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Informace k odjezdu</div>
              <div className='news__item__message'>
                <b>Odjezd autobusu z Prahy:</b> Sobota 10. 8. 2024, sraz ve
                12:30, odjezd ve 13:00 z parkoviště u Polikliniky Budějovická na
                rohu ulic A. Staška a ulice Na Krčské stráni.
                <br />
                <div>
                  <a
                    href='https://www.google.com/maps/place/Parkoviště,+140+00+Praha+4/@50.042149,14.4494601,18.39z/data=!4m8!1m2!2m1!1sA.+Staška,+Praha+4!3m4!1s0x470b93f26f1b71df:0x2dd466155929f766!8m2!3d50.0423541!4d14.4494978'
                    className='news__item__link'
                    target='blank'
                    style={{ marginRight: '10px' }}
                  >
                    Zobrazit mapu
                  </a>
                  <a href={Praha} className='news__item__link' target='blank'>
                    Seznam pasažérů
                  </a>
                </div>
                <br />
                <b>Odjezd autobusu ze Staré Boleslavi:</b>
                Sobota 10. 8. 2024, sraz ve 12:30, odjezd ve 13:00 z adresy
                Jungmanova 765/8.
                <div>
                  <a
                    href='https://www.google.com/maps/place/Jungmannova+765%2F8,+Star%C3%A1+Boleslav,+250+01+Brand%C3%BDs+nad+Labem-Star%C3%A1+Boleslav/@50.1952692,14.675636,17z/data=!3m1!4b1!4m5!3m4!1s0x470bf1b0080725f3:0x2d9ecd320f9c7a78!8m2!3d50.1952658!4d14.6778247'
                    className='news__item__link'
                    target='blank'
                    style={{ marginRight: '10px' }}
                  >
                    Zobrazit mapu
                  </a>
                  <a href={Brandys} className='news__item__link' target='blank'>
                    Seznam pasažérů
                  </a>
                </div>
                <br />
                <b>Individuální příjezd na tábor:</b>
                Sobota 10. 8. 2024, nejdříve v 16:00 na adrese LDT Tomášov,
                Tomášov 11, 407 79 Mikulášovice.
                <a
                  href='https://goo.gl/maps/6SavEkJBvF6TXf8G6'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              30.4.<span>2024</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Doplňkové informace</div>
              <div className='news__item__message'>
                Vážení rodiče, tábor se nám kvapem blíží a tak je tu první várka
                informací. Do sekce Ročník 2024 jsme doplnili všechny potřebné
                dokumenty k odjezdu na tábor. I letos bychom chtěli poprosit
                rodiče dětí, které už mají vlastní cestovní pas nebo občanský
                průkaz, o jeho přibalení z důvodu možného výletu do nedalekého
                Německa. <br /> <br />
                Tématem letošního ročníku jsou dobrodržuství na půdě legendární
                magické akademie Ravencroft, kde se koná i tradiční bál. Prosíme
                tedy o přibalení čarodějného kostýmu, který se může nést ve
                znamení některého z přírodních živlů, jejichž magie se na
                akedemii již staletí vyučuje. Už teď se moc těšíme, až si je v
                srpnu budeme moci prohlédnout. S pozdravem tým Hlavas.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              5.3.<span>2024</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Přihlášky ukončeny</div>
              <div className='news__item__message'>
                Dnešním dnem byly oficiálně ukončeny příhlášky z důvodu plné
                kapacity tábora. Aktualizovaný seznam účastníků naleznete v
                sekci Ročník 2024. Děkujeme za pochopení, tým Hlavas.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              15.1.<span>2024</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Přihlášky spuštěny</div>
              <div className='news__item__message'>
                Dnešním dnem byly oficiálně spuštěny přihlášky na letošní ročník
                tábora, konajícího se v termínu 10.8. - 24.8. 2024 v táborové
                základně Tomášov. Přihlášku naleznete v sekci Ročník 2024.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              21.9.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Táborové aftermovie</div>
              <div className='news__item__message'>
                To, že je náš vedoucí Matouš šikovný kameraman a střihač, po
                letošním filmu již jistě víte. Teď si pro vás připravil
                nostalgickou tečku v podobě Aftermovie z Tomášova 2023. Měsíc po
                konci tábora si tak můžete naposledy užít výlet za Dětmi rodu
                Bertinelli do Tomášova.
                <a
                  href='https://youtu.be/kWe_dtjcJMU?si=Q6FKJkKFX8axtC4Q'
                  className='news__item__link'
                  target='blank'
                >
                  Přehrát video
                </a>
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              31.8.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>
                Poděkování + ztráty a nálezy
              </div>
              <div className='news__item__message'>
                Naši milí táborníci, chtěli bychom vám z celého srdce poděkovat,
                že jsme společně i tento rok mohli zakončit letní prázdniny na
                našem táboře. My jsme si ho náramně užili, a tak doufáme, že vy
                ještě víc. Děkujeme a těšíme se, že se s vámi znovu sejdeme zase
                za rok. Jako každý rok, i letos nám zbyly nějaké věci z
                kategorie Ztráty a nálezy, odkaz přikládáme níže. V případě
                potřeby kontaktuje prosím Hanku Pelcovou, která má všechny věci
                u sebe.
                <a
                  href={LostFounds}
                  className='news__item__link'
                  target='blank'
                >
                  Ztráty a nálezy
                </a>
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              24.8.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Rozpis odjezdů z tábora</div>
              <div className='news__item__message'>
                Dovolujeme si vás informovat, že v sobotu 26.8. proběhne odjezd
                z Tomášova ve 12:00. Příjezd autobusu do Brandýsa nad Labem je
                plánovaný na 14:30, do Prahy pak na 15:00. Případné drobné
                odchylky od plánu budeme komunikovat na našich sociálních
                sítích.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              9.8.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Doplňkové informace</div>
              <div className='news__item__message'>
                Vážení rodiče, pro jistotu bychom Vám chtěli připomenout, že
                letošní tábor nedisponuje lůžkovinami, proto nezapomeňte svým
                ratolestem přibalit spacák a karimatku. Rovněž by s sebou každé
                dítě mělo mít toaletní papír. Rodiče, kteří povezou své dítě na
                tábor individuálně, bychom chtěli poprosit o příjezd na táborovu
                základnu nejdříve v 16:00. Děkujeme za pochopení a už se moc
                těšíme.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              7.8.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Informace k odjezdu</div>
              <div className='news__item__message'>
                <b>Odjezd autobusu z Prahy:</b> Sobota 12. 8. 2023, sraz ve
                12:30, odjezd ve 13:00 z parkoviště u Polikliniky Budějovická na
                rohu ulic A. Staška a ulice Na Krčské stráni.
                <br />
                <a
                  href='https://www.google.com/maps/place/Parkoviště,+140+00+Praha+4/@50.042149,14.4494601,18.39z/data=!4m8!1m2!2m1!1sA.+Staška,+Praha+4!3m4!1s0x470b93f26f1b71df:0x2dd466155929f766!8m2!3d50.0423541!4d14.4494978'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
                <br />
                <b>Odjezd autobusu ze Staré Boleslavi:</b>
                Sobota 12. 8. 2023, sraz ve 12:30, odjezd ve 13:00 z adresy
                Jungmanova 765/8.
                <a
                  href='https://www.google.com/maps/place/Jungmannova+765%2F8,+Star%C3%A1+Boleslav,+250+01+Brand%C3%BDs+nad+Labem-Star%C3%A1+Boleslav/@50.1952692,14.675636,17z/data=!3m1!4b1!4m5!3m4!1s0x470bf1b0080725f3:0x2d9ecd320f9c7a78!8m2!3d50.1952658!4d14.6778247'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
                <br />
                <b>Individuální příjezd na tábor:</b>
                Sobota 12. 8. 2023, nejdříve v 16:00 na adrese LDT Tomášov,
                Tomášov 11, 407 79 Mikulášovice.
                <a
                  href='https://goo.gl/maps/6SavEkJBvF6TXf8G6'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              9.7.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Aktuality k táboru</div>
              <div className='news__item__message'>
                Vážení táboroví příznivci, tábor se nám kvapem blíží a proto
                bychom Vám rádi oznámili první várku novinek. 1) Chtěli bychom
                poprosit rodiče dětí, které už mají vlastní cestovní pas nebo
                občanský průkaz, o jeho přibalení z důvodu možného výletu do
                nedalekého Německa. 2) Jak jste si již mohli všimnout nejen z
                našich pravidelných Instagramových příspěvků, tématem letošního
                tábora je mafie a podsvětí města Grain Island ve 30. letech,
                proto Vás chceme poprosit o přibalení tématického kostýmu. 3)
                Prosíme, abyste nám již neposílali požadavky na ubytování a
                umístění do oddílů. Z důvodu jejich četnosti a kapacit v
                jednotlivých oddílech nemůžeme vyhovět úplně všem, ale snažili
                jsme se zapracovat drtivou většinu z nich. Už se velice těšíme,
                až nám to za 5 týdnů vypukne. S pozdravem tým Hlavas.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              27.3.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Přihlášky ukončeny</div>
              <div className='news__item__message'>
                Dnešním dnem byly oficiálně ukončeny příhlášky z důvodu plné
                kapacity tábora. Děkujeme za pochopení, tým Hlavas.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              29.1.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Přihlášky spuštěny</div>
              <div className='news__item__message'>
                Dnešním dnem byly oficiálně spuštěny přihlášky na letošní ročník
                našeho tábora, konajícího se v termínu 12.8. - 26.8. 2023 v
                táborové základně Tomášov. Všechny důležité dokumenty naleznete
                v sekci Ročník 2023. Na našem táborovém Instagramu začneme brzy
                sdílet materiály k letošní celotáborové hře. Všichni už se moc
                těšíme.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              11.1.<span>2023</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>První info k táboru 2023</div>
              <div className='news__item__message'>
                Vážení táboroví příznivci, technická část přípravy dalšího
                ročníku vrcholí a my bychom Vás rádi informovali, že od 1. 2.
                2023 budou spuštěny přihlášky. V souvislosti s tím se můžete
                těšit na první střípky z naší celotáborové hry, která letos nese
                tajemný název Děti rodu Bertinelli.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              6.9.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Ohlédnutí za táborem</div>
              <div className='news__item__message'>
                Vážení kamarádi, táborníci, praktikanti, vedoucí, kuchaři,
                zdravotnice, rodiče a táboroví příznivci, chtěli bychom Vám všem
                poděkovat za letošní Stvolínky. V kontextu toho, jak byl pro nás
                letošní rok složitý, jsme rádi, že se všechno podařilo a tábor
                jsme si všichni užili na maximum a věříme, že by z nás všech měl
                Zdeněk radost. V rámci potáborového vzpomínání jsme doplnili
                sekci Historie, ve které můžete nalézt kompletní výpis 32
                ročníků tábora, včetně celotáborových her. V průběhu příštích
                několika dní doplníme i historické fotogalerie. V duchu letošní
                hry se na chvíli podíváme do budoucnosti - ročník 2023 už je v
                přípravách a tak se i příští rok můžete těšit na vše, co na
                našich táborech máte tolik rádi. Přejeme Vám hezký zbytek roku a
                v létě na viděnou.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              21.8.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Rozpis odjezdů z tábora</div>
              <div className='news__item__message'>
                Půlku tábora máme úspěšně a ve zdraví za sebou. Dovolujeme si
                vás informovat, že v sobotu 27.8. proběhne odjezd ze Stvolínek
                ve 12:00 a příjezd autobusů do Prahy i do Brandýsa nad Labem je
                plánovaný na 14:00.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              19.8.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Fotogalerie a noviny</div>
              <div className='news__item__message'>
                Provizorně jsme spustili sekci Galerie, ve které můžete nalézt
                odkaz na fotogalerii a archiv novin, který se bude postupně
                doplňovat.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              13.8.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Stvolínky 2022 zahájeny</div>
              <div className='news__item__message'>
                S radostí oznamujeme, že všichni účastníci letošního tábora v
                pořádku dorazili na naší novou základnu a odstartovali tak
                čtrnáctidenní maraton her a různých druhů zábavy.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              11.8.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Sálová obuv</div>
              <div className='news__item__message'>
                Jelikož naše letošní táborová základna disponuje prostornou
                tělocvičnou, chtěli bychom vás požádat o přibalení sálové obuvi
                se světlou podrážkou dětem. Děkujeme a pozítří na viděnou.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              7.8.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Informace k odjezdu</div>
              <div className='news__item__message'>
                <b>Odjezd autobusu z Prahy:</b> Sobota 13. 8. 2022, sraz v
                11:30, odjezd ve 12:00 z parkoviště u Polikliniky Budějovická na
                rohu ulic A. Staška a ulice Na Krčské stráni.
                <br />
                <a
                  href='https://www.google.com/maps/place/Parkoviště,+140+00+Praha+4/@50.042149,14.4494601,18.39z/data=!4m8!1m2!2m1!1sA.+Staška,+Praha+4!3m4!1s0x470b93f26f1b71df:0x2dd466155929f766!8m2!3d50.0423541!4d14.4494978'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
                <br />
                <b>Odjezd autobusu ze Staré Boleslavi:</b>
                Sobota 13. 8. 2022, sraz v 11:30, odjezd ve 12:00 z adresy
                Jungmanova 765/8.
                <a
                  href='https://www.google.com/maps/place/Jungmannova+765%2F8,+Star%C3%A1+Boleslav,+250+01+Brand%C3%BDs+nad+Labem-Star%C3%A1+Boleslav/@50.1952692,14.675636,17z/data=!3m1!4b1!4m5!3m4!1s0x470bf1b0080725f3:0x2d9ecd320f9c7a78!8m2!3d50.1952658!4d14.6778247'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
                <br />
                <b>Individuální příjezd na tábor:</b>
                Sobota 13. 8. 2022, nejdříve ve 14:00 na adrese LDT Radost,
                Stvolínky ev. č. 98, 470 01 Stvolínky.
                <a
                  href='https://www.google.com/maps/@50.6380066,14.4272603,17.22z'
                  className='news__item__link'
                  target='blank'
                >
                  Zobrazit mapu
                </a>
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              15.6.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Informace pro rodiče</div>
              <div className='news__item__message'>
                Po řadě dotazů bychom Vás chtěli ubezpečit, že společná doprava
                autobusem na tábor proběhne i letos tak, jak jsme všichni
                zvyklí, individuální doprava je pouze možností například pro
                účastníky, kteří to mají do Stvolínek blíže než do Prahy apod.
                Do sekce Ročník 2022 jsme nově přidali podsekci "Pro rodiče",
                která sdružuje informace a obsahuje všechny důležité dokumenty
                potřebné k odjezdu na tábor. Přípravy jsou v plném proudu a už
                se všichni těšíme, až to za dva měsíce vypukne.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              27.4.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Smuteční oznámení</div>
              <div className='news__item__message'>
                Vážení a milí rodiče a táboroví přátelé, stejně, jako je tento
                rok smutný a těžký pro celý svět, je smutný i pro náš svět
                táborový. Náhle a nečekaně nás navždy opustil Zdeněk - Hlavas.
                Je to obrovská a bolestná ztráta, neboť jak všichni víte, právě
                díky němu a kvůli němu fungoval tábor skvěle po celé ty dlouhé
                roky. My všichni, kterými se Zdeněk obklopil při organizování
                tábora, chceme a budeme pokračovat nadále v jeho odkazu, za
                který tábor považujeme. Tábor letos bude. Pokud budete cokoliv
                potřebovat, prosíme, obraťte se na Hanku Schindlerovou. Děkujeme
                za pochopení a za podporu, děkujeme všem, kteří věnují Zdeňkovi
                vzpomínku. Věříme, že společně vše zvládneme a tábor bude ještě
                dlouhou dobu dělat radost spoustě dětí i nám všem.
              </div>
            </div>
          </div>

          <div className='news__item'>
            <div className='news__item__date'>
              27.4.<span>2022</span>
            </div>
            <div className='news__item__text-box'>
              <div className='news__item__title'>Nový web spuštěn</div>
              <div className='news__item__message'>
                Dnes byl spuštěn nový táborový web. Všechny důležité informace k
                aktuálnímu ročníku naleznete v příslušné sekci. Omluvte prosím
                dočasnou nefunkčnost sekcí galerie a historie, budou postupně
                doplněny. Děkujeme za pochopení.
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default News
