import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import PageHeader from '../layout/PageHeader'

const Contact = () => {
  return (
    <>
      <Navbar />
      <PageHeader />
      <section className='contact'>
        <div className='contact__left'>
          <div className='about__left__title'>Kontaktní informace</div>
          <div className='contact__box'>
            <div className='contact__box__title'>Hlavas, z.s.</div>
            <div className='contact__box__text'>Nademlejnská 651/7</div>
            <div className='contact__box__text'>198 00 Praha 9 - Hloubětín</div>
            <div className='contact__box__text'>IČ: 095 92 954</div>
          </div>
          <div className='contact__box'>
            <div className='contact__box__text'>ČÚ: 123-3451350257/0100</div>
            <a
              href='https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=1101405'
              className='contact__box__text'
              target='blank'
            >
              Výpis z rejstříku &rarr;
            </a>
          </div>

          <div className='contact__box'></div>
          <div className='contact__box'></div>
          <div className='contact__box'>
            <div className='contact__box__title'>Kontaktní osoba</div>
            <div className='contact__box__text'>Hana Pelcová</div>
            <div className='contact__box__text'>hlavas@hotmail.com</div>
            <div className='contact__box__text'>+420 739 001 411</div>
          </div>
        </div>
        <div className='contact__right'>
          <div className='about__left__title'>Sídlo spolku</div>
          <iframe
            className='contact__map'
            title='sidlo'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.1218456795277!2d14.525665716188152!3d50.10272627942896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bed2a6ca216e9%3A0xd97ac56f2ccbd01c!2sNademlejnsk%C3%A1%20651%2C%20198%2000%20Praha%209-Hloub%C4%9Bt%C3%ADn!5e0!3m2!1scs!2scz!4v1650889887304!5m2!1scs!2scz'
          ></iframe>
        </div>
      </section>
      <section className='contact-camp'>
        <div className='about__left__title'>Korespondenční adresa tábora</div>
        <div className='contact__box'>
          <div className='contact__box__title'>LDT Tomášov</div>
          <div className='contact__box__text'>Tomášov 11</div>
          <div className='contact__box__text'>407 79 Mikulášovice</div>
        </div>
        <iframe
          className='contact-camp__map'
          title='tabor'
          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d628.3066032580502!2d14.326661951070585!3d50.95651802297231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x456347527f28af27!2zVG9tw6HFoW92LCA0MDcgNzkgTWlrdWzDocWhb3ZpY2U!5e0!3m2!1scs!2scz!4v1674556792360!5m2!1scs!2scz'
        ></iframe>
      </section>
      <Footer />
    </>
  )
}

export default Contact
