import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
      <nav className='navbar'>
        <div className='navbar__left-box'>
          <Link to='/' className='list list__item'>
            Domů
          </Link>
          <Link to='/rocnik' className='list list__item'>
            Ročník 2024
          </Link>
          <Link to='/aktuality' className='list list__item'>
            Aktuality
          </Link>
        </div>

        <div className='navbar__logo-box'>
          <Link to='/'>
            <div className='navbar__logo-box__circle'></div>
          </Link>
        </div>

        <div className='navbar__right-box'>
          <Link to='/galerie' className='list list__item'>
            Galerie
          </Link>
          <Link to='/historie' className='list list__item'>
            Historie
          </Link>
          <Link to='/kontakty' className='list list__item'>
            Kontakty
          </Link>
          <div className='navigation-mobile'>
            <input
              type='checkbox'
              className='navigation-mobile__checkbox'
              id='navi-toggle'
            />

            <label for='navi-toggle' className='navigation-mobile__button'>
              <span className='navigation-mobile__icon'>&nbsp;</span>
            </label>

            <div className='navigation-mobile__background'>&nbsp;</div>

            <nav className='navigation-mobile__nav'>
              <ul className='navigation-mobile__list'>
                <li className='navigation-mobile__item'>
                  <Link to='/' className='navigation-mobile__link'>
                    Domů
                  </Link>
                </li>
                <li className='navigation-mobile__item'>
                  <Link to='/rocnik' className='navigation-mobile__link'>
                    Ročník 2024
                  </Link>
                </li>
                <li className='navigation-mobile__item'>
                  <Link to='/aktuality' className='navigation-mobile__link'>
                    Aktuality
                  </Link>
                </li>
                <li className='navigation-mobile__item'>
                  <Link to='/galerie' className='navigation-mobile__link'>
                    Galerie
                  </Link>
                </li>
                <li className='navigation-mobile__item'>
                  <Link to='/historie' className='navigation-mobile__link'>
                    Historie
                  </Link>
                </li>
                <li className='navigation-mobile__item'>
                  <Link to='/kontakty' className='navigation-mobile__link'>
                    Kontakty
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
