import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import PageHeader from '../layout/PageHeader'

// GALLERY IMPORTS
import Tomasov24 from '../resources/imgs/covers/Tomasov24.jpg'
import Tomasov23 from '../resources/imgs/covers/Tomasov23.jpeg'
import Stvolinky22 from '../resources/imgs/covers/Stvolinky22.jpeg'
import Bozanov21 from '../resources/imgs/covers/Bozanov21.jpeg'
import Bozanov20 from '../resources/imgs/covers/Bozanov20.jpeg'
import Bozanov19 from '../resources/imgs/covers/Bozanov19.jpeg'
import Bozanov18 from '../resources/imgs/covers/Bozanov18.jpeg'
import Bozanov17 from '../resources/imgs/covers/Bozanov17.jpeg'
import Cmelak16 from '../resources/imgs/covers/Cmelak16.jpeg'

const Gallery = () => {
  return (
    <>
      <Navbar />
      <PageHeader />
      <section className='news'>
        <div className='news__title'>Galerie</div>
        <div className='gallery'>
          <a
            href='https://photos.app.goo.gl/ws95ezYPnCdjjgPT9'
            target='blank'
            className='gallery-item'
          >
            <img src={Tomasov24} alt='Tomášov 2024' className='gallery-img' />
            <div className='gallery-title'>Tomášov 2024</div>
          </a>
          <a
            href='https://photos.app.goo.gl/99TSw1kGsmqnuhYC9'
            target='blank'
            className='gallery-item'
          >
            <img src={Tomasov23} alt='Tomášov 2023' className='gallery-img' />
            <div className='gallery-title'>Tomášov 2023</div>
          </a>
          <a
            href='https://photos.app.goo.gl/8meVzuvRCvsRnLgT6'
            target='blank'
            className='gallery-item'
          >
            <img
              src={Stvolinky22}
              alt='Stvolínky 2022'
              className='gallery-img'
            />
            <div className='gallery-title'>Stvolínky 2022</div>
          </a>
          <a
            href='https://photos.app.goo.gl/5rJMfNZHfc2uy39R7'
            target='blank'
            className='gallery-item'
          >
            <img src={Bozanov21} alt='Božanov 2021' className='gallery-img' />
            <div className='gallery-title'>Božanov 2021</div>
          </a>
          <a
            href='https://photos.app.goo.gl/MA9WB47smDXv7nZ78'
            target='blank'
            className='gallery-item'
          >
            <img src={Bozanov20} alt='Božanov 2020' className='gallery-img' />
            <div className='gallery-title'>Božanov 2020</div>
          </a>
          <a
            href='https://photos.app.goo.gl/pPUzQt8ZC5urJd4V8'
            target='blank'
            className='gallery-item'
          >
            <img src={Bozanov19} alt='Božanov 2019' className='gallery-img' />
            <div className='gallery-title'>Božanov 2019</div>
          </a>
          <a
            href='https://photos.app.goo.gl/WcmutuDGyR9GPvnRA'
            target='blank'
            className='gallery-item'
          >
            <img src={Bozanov18} alt='Božanov 2018' className='gallery-img' />
            <div className='gallery-title'>Božanov 2018</div>
          </a>
          <a
            href='https://photos.app.goo.gl/4G2gD3GvmwrAMkTWA'
            target='blank'
            className='gallery-item'
          >
            <img src={Bozanov17} alt='Božanov 2017' className='gallery-img' />
            <div className='gallery-title'>Božanov 2017</div>
          </a>
          <a
            href='https://photos.app.goo.gl/WbpxhqJbG47VtDLG6'
            target='blank'
            className='gallery-item'
          >
            <img src={Cmelak16} alt='Čmelák 2016' className='gallery-img' />
            <div className='gallery-title'>Čmelák 2016</div>
          </a>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Gallery
