import React, { useState } from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import PageHeader from '../layout/PageHeader'
import Members from '../layout/Members'

// GALLERY IMPORTS
import Tomasov1 from '../resources/imgs/covers/tomasov1.jpg'
import Tomasov2 from '../resources/imgs/covers/tomasov2.jpg'
import Tomasov3 from '../resources/imgs/covers/tomasov3.jpg'
import Tomasov4 from '../resources/imgs/covers/tomasov4.jpg'

// DOCS IMPORTS
import Seznam from '../resources/docs/seznam-veci.pdf'
import Posudek from '../resources/docs/posudek-zpusobilosti.pdf'
import Bezinfekncost from '../resources/docs/prohlaseni-bezinfekcnost.pdf'
import Gdpr from '../resources/docs/prohlaseni-gdpr.pdf'
import Souhlas from '../resources/docs/souhlas-info.pdf'

const Event = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <>
      <Navbar />
      <PageHeader />
      <div className='camp'>
        <div className='camp__left'>
          <div className='camp__left__title'>Ročník 2024</div>
          <div className='camp__left__info'>
            Letošní ročník tábora se koná ve dnech <b>10.8. - 24.8.2024.</b> I v
            letošním roce jsme se rozhodli pro táborovou základnu <b>Tomášov</b>{' '}
            poblíž obce Mikulášovice ve <b>Šluknovském výběžku.</b>
          </div>
          <div className='camp__left__info'>
            <b>Tábor je určen pro děti ve věku 6 – 15 let,</b> o které se
            postará parta zkušených vedoucích a aktivních praktikantů, kteří
            sami na minulých táborech dost často vyrostli a berou ho jako svoji
            srdeční záležitost.
          </div>
          <div className='camp__left__info'>
            Ubytování je pro menší děti zajištěno ve <b>zděných budovách,</b>{' '}
            pro starší v <b>pětilůžkových chatkách.</b> Stravování probíhá 5x
            denně, pitný režim je zajištěn po celý den. V areálu se nachází{' '}
            <b>
              letní jídelna, společenské místnosti, táborové ohniště a travnatá
              hřiště.
            </b>{' '}
            Děti se kromě celotáborové hry mohou těšit i na koupání, táboráky,
            diskotéky, výlety a další pestrý program.
          </div>
          <div className='camp__left__info'>
            Cena pobytu je stanovena na <b>6 000 Kč.</b> Autobusová doprava pro
            odvoz dětí na tábor i zpět je <b>zajištěna</b> pro všechny děti,
            které se tábora zúčastní. Prosíme rodiče dětí, kteří dopraví své
            děti na tábor <b>individuálně,</b> aby nám tuto informaci sdělili na
            náš táborový email <b>hlavas@hotmail.com.</b>
          </div>
        </div>
        <div className='camp__right'>
          <div className='camp__right__gallery'>
            <img
              src={Tomasov1}
              alt='Tomášov 1'
              className='camp__right__gallery__photo'
            />
            <img
              src={Tomasov2}
              alt='Tomášov 2'
              className='camp__right__gallery__photo'
            />
          </div>
          <div className='camp__right__gallery'>
            <img
              src={Tomasov3}
              alt='Tomášov 3'
              className='camp__right__gallery__photo'
            />
            <img
              src={Tomasov4}
              alt='Tomášov 4'
              className='camp__right__gallery__photo'
            />
          </div>
        </div>
      </div>

      <div className='camp__strip'>
        <div className='camp__strip__box'>
          <button className='camp__strip__btn' onClick={openModal}>
            Seznam účastníků 2024
          </button>
        </div>
      </div>

      <div className='about'>
        <div className='about__left'>
          <div className='camp__left__title'>Pro rodiče</div>
          <div className='about__left__info'>
            Zálohu ve výši 1 000 Kč prosíme uhradit nejpozději do{' '}
            <b>30.4.2024</b>, zbytek částky pak nejpozději do <b>30.6.2024</b>{' '}
            na číslo účtu <b>123-3451350257/0100,</b> do zprávy pro příjemce
            uveďte jméno a příjmení dítěte.
          </div>
          <br></br>
          <div className='about__left__info'>
            Prosíme rodiče, pokud by měli požadavky na{' '}
            <b>společné bydlení dětí s jejich kamarády v jednom pokoji,</b>{' '}
            případně zařazení do stejného oddílu, aby nám tyto informace sdělili
            nejpozději do <b>30.6.2024</b> na náš táborový email{' '}
            <b>hlavas@hotmail.com,</b> pokusíme se vyhovět co nejlépe všem
            požadavkům, pokud to bude s ohledem na věk dětí a ubytovací kapacity
            možné. <b>Prosíme však o respektování konečného termínu.</b>
          </div>
        </div>
        <div className='about__right'>
          <div className='camp__left__title'>Dokumenty</div>
          <a
            href={Seznam}
            class='camp__docs-title'
            target='blank'
            rel='noopener noreferrer'
          >
            Seznam věcí &rarr;
          </a>
          <a
            href={Posudek}
            class='camp__docs-title'
            target='blank'
            rel='noopener noreferrer'
          >
            Posudek zdravotní způsobilosti &rarr;
          </a>
          <a
            href={Bezinfekncost}
            class='camp__docs-title'
            target='blank'
            rel='noopener noreferrer'
          >
            Prohlášení rodičů o bezinfekčnosti &rarr;
          </a>
          <a
            href={Gdpr}
            class='camp__docs-title'
            target='blank'
            rel='noopener noreferrer'
          >
            Prohlášení zákonných zástupců GDPR &rarr;
          </a>
          <a
            href={Souhlas}
            class='camp__docs-title'
            target='blank'
            rel='noopener noreferrer'
          >
            Souhlas rodičů s poskytováním informací o zdravotním stavu &rarr;
          </a>
        </div>
      </div>
      <Members isOpen={isOpen} closeModal={closeModal} />
      <Footer />
    </>
  )
}

export default Event
