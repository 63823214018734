import React from 'react'

const TableRow = ({ member }) => {
  return (
    <div className='modal-table-row'>
      <div className='modal-table-item modal-table-item-1'>{member.number}</div>
      <div className='modal-table-item modal-table-item-2'>{member.name}</div>
      <div className='modal-table-item modal-table-item-3'>
        {member.category}
      </div>
    </div>
  )
}

export default TableRow
