import React from 'react'
import Modal from 'react-modal'
import TableRow from '../components/TableRow'

import members from '../resources/members.json'

import { HiX } from 'react-icons/hi'

const Members = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className='modal modal-stabilization'
      contentLabel='Bulk Edit'
      overlayClassName='modal-overlay'
    >
      <button className='modal-close' onClick={closeModal}>
        <HiX className='modal-close-icon' />
      </button>
      <div className='modal-content'>
        <div className='modal-table'>
          <div className='modal-table-heading'>
            Seznam účastníků ročníku 2024
          </div>
          <div className='modal-table-head-row'>
            <div className='modal-table-item modal-table-item-1'>Č.</div>
            <div className='modal-table-item modal-table-item-2'>Jméno</div>
            <div className='modal-table-item modal-table-item-3'>Kat.</div>
          </div>
          {members.map(member => (
            <TableRow member={member} />
          ))}
          <div className='modal-table-ending'></div>
        </div>
      </div>
    </Modal>
  )
}

export default Members
