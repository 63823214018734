import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import MainPage from './pages/MainPage'
import Event from './pages/Event'
import News from './pages/News'
import Gallery from './pages/Gallery'
import History from './pages/History'
import Contact from './pages/Contact'

// AUTOMATICALLY SCROLL ON TOP WHEN ROUTE CHANGES
const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/rocnik' element={<Event />} />
          <Route path='/aktuality' element={<News />} />
          <Route path='/galerie' element={<Gallery />} />
          <Route path='/historie' element={<History />} />
          <Route path='/kontakty' element={<Contact />} />
          <Route path='*' element={<MainPage />} />
        </Routes>
      </Router>
    </>
  )
}

export default App
